import { LogoImage } from "../utils/Constants/dataConstant";
import "./style/FooterStyle.css";

const Footer = () => {
  return (
    <footer className="text-center text-lg-start bg-body-tertiary text-muted content-image">
      <div className="overlay"></div>
      <section className="py-5">
        <div className="container text-center text-md-start">
          <div className="row">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-white">
                <i className="fas fa-gem me-3"></i>Modfur Industry
              </h6>
              <p className="text-white fs-6">
                ModFur Industries is a leading furniture manufacturing company
                renowned for its innovative designs and high-quality
                craftsmanship. With a commitment to excellence, we create
                furniture solutions that blend style, durability, and
                functionality to transform any space.
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-white">
                Contact
              </h6>
              <p className="text-white">
                <i className="fas fa-home me-3"></i>Anant Compound, Shed No.-5,
                Village-Anangpur, Near St. Bridge Mohan Lal Sr. Sec. School,
                Faridabad, Haryana, 121003
              </p>
              <p className="text-white">
                <i className="fas fa-envelope me-3"></i>
                info@modfur.in
              </p>
              <p className="text-white">
                <i className="fas fa-phone me-3"></i>+011 406 12820
              </p>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3 mx-auto d-flex justify-content-center align-items-center">
              <img
                src={LogoImage.logo}
                alt="Modfur Logo"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-between bg-transparent text-white p-4">
        <div className="text-center">© 2024 Copyright ModFur Industries</div>
        <div className="d-flex justify-content-center align-items-center">
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-google"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
