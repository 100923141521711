import Banner from "../../components/Banner";
import getBrowserInfo from "../../components/BrowserInfoHook";
import Image from "../../components/Image";
import NavBar from "../../components/NavBar";
import { AboutImage } from "../../utils/Constants/dataConstant";
import "./style.css";
import SwiperComponent from "./SwiperComponent/SwiperComponent";
import SwiperComponent2 from "./SwiperComponent/SwiperComponent2";
import Testimonial from "./Testimonial/Testimonial";

const About = () => {
  const { browserName } = getBrowserInfo();
  return (
    <>
      <NavBar pageName="About" />
      <div className="mil-wrapper">
        <div id="content">
          <Banner bannerName="About Us" />
          <section className="mil-soft-bg mil-relative">
            <Image src="img/other/bg.svg" className="mil-bg-img" alt="image" />
            <div className="container mil-p-120-90">
              <div className="row">
                <div className="col-12">
                  <div className="mil-center mil-mb-90">
                    <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                      Services
                    </span>
                    <h2 className="mil-upper mil-up">What We Do</h2>
                  </div>
                </div>
                <div className="col-lg-4">
                  <a
                    href="/service"
                    className="mil-service-card mil-up mil-mb-30"
                  >
                    <div className="mil-card-number">01.</div>
                    <div className="mil-center">
                      <div className="mil-icon mil-icon-lg mil-mb-30">
                        <img src="img/icons/3.svg" alt="icon" />
                      </div>
                      <h4 className="mil-upper mil-mb-20">OFFICE FURNITURE</h4>
                      <div className="mil-divider-sm mil-mb-20"></div>
                      <p className="mil-service-text">
                        Enhance your workspace with ModFur Industries' premium
                        office furniture. Our products are designed to promote
                        productivity and reflect modern aesthetics, providing
                        comfort
                      </p>
                      <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                        <img src="img/icons/1.svg" alt="icon" />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    href="/service"
                    className="mil-service-card mil-up mil-mb-30"
                  >
                    <div className="mil-card-number">02.</div>
                    <div className="mil-center">
                      <div className="mil-icon mil-icon-lg mil-mb-30">
                        <img src="img/icons/5.svg" alt="icon" />
                      </div>
                      <h4 className="mil-upper mil-mb-20">MODULAR KITCHEN</h4>
                      <div className="mil-divider-sm mil-mb-20"></div>
                      <p className="mil-service-text">
                        Elevate your kitchen with ModFur Industries' stylish and
                        durable modular kitchen furniture. We craft pieces that
                        blend beauty and practicality, making your kitchen a
                        centerpiece of your home.
                      </p>
                      <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                        <img src="img/icons/1.svg" alt="icon" />
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    href="service.html"
                    className="mil-service-card mil-up mil-mb-30"
                  >
                    <div className="mil-card-number">03.</div>
                    <div className="mil-center">
                      <div className="mil-icon mil-icon-lg mil-mb-30">
                        <img src="img/icons/4.svg" alt="icon" />
                      </div>
                      <h4 className="mil-upper mil-mb-20">CUSTOM SOLUTIONS</h4>
                      <div className="mil-divider-sm mil-mb-20"></div>
                      <p className="mil-service-text">
                        Tailor-made furniture solutions to meet your specific
                        needs. At ModFur Industries, we offer customization
                        options to ensure that our furniture perfectly fits your
                        unique space and style.
                      </p>
                      <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                        <img src="img/icons/1.svg" alt="icon" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-3">
            <div className="container mil-p-0-120">
              <div className="mil-contact-frame mil-up">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-4 mil-mb-30">
                    <p className="mil-dark mil-up mil-mb-10">Email address</p>
                    <h4 className="mil-thin mil-up">info@modfur.in</h4>
                  </div>
                  <div className="col-lg-4 mil-mb-30">
                    <p className="mil-dark mil-up mil-mb-10">Connect With Us</p>
                    <h4 className="mil-thin mil-up">+ 011 406 12820</h4>
                  </div>
                  <div className="col-lg-4 mil-mb-30">
                    <a href="/contact" className="mil-button mil-up mil-fw">
                      Free Consultation
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {browserName === "Safari" ? (
            <SwiperComponent />
          ) : (
            <section>
              <div className="container mil-p-0-30">
                <div className="row justify-content-between align-items-center flex-sm-row-reverse">
                  <div className="col-lg-5">
                    <div className="mil-mb-90">
                      <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                        Work
                      </span>
                      <h2 className="mil-upper mil-up mil-mb-30">
                        What We Have Done
                      </h2>
                      <p className="mil-up mil-mb-40">
                        Our extensive portfolio features a wide array of
                        projects, ranging from residential homes to commercial
                        complexes, demonstrating our versatility, craftsmanship,
                        and dedication to client satisfaction.
                      </p>
                      <div className="mil-up">
                        <a href="/product" className="mil-link mil-upper">
                          See Products{" "}
                          <span className="mil-arrow">
                            <img src="img/icons/1.svg" alt="arrow" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mil-illustration-slider-frame mil-up mil-mb-90">
                      <div className="swiper-container mil-illustration-slider">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div
                              className="mil-illustration"
                              data-swiper-parallax-x="50"
                              data-swiper-parallax-scale="1.3"
                            >
                              <div className="mil-image-frame">
                                <img src={AboutImage.slide1} alt="image1" />
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div
                              className="mil-illustration"
                              data-swiper-parallax-x="50"
                              data-swiper-parallax-scale="1.3"
                            >
                              <div className="mil-image-frame">
                                <img src={AboutImage.slide2} alt="image2" />
                              </div>
                            </div>
                          </div>

                          <div className="swiper-slide">
                            <div
                              className="mil-illustration"
                              data-swiper-parallax-x="50"
                              data-swiper-parallax-scale="1.3"
                            >
                              <div className="mil-image-frame">
                                <img src={AboutImage.slide3} alt="image3" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mil-illustration-slider-nav mil-up">
                        <div className="mil-nav-buttons">
                          <div className="mil-slider-button mil-illustration-prev">
                            Prev
                          </div>
                          <div className="mil-slider-button mil-illustration-next">
                            Next
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="mil-relative">
            <div className="container mil-p-0-60">
              <div className="row align-items-end">
                <div className="col-lg-6">
                  <div className="mil-mb-90">
                    <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                      How it Works
                    </span>
                    <h2 className="mil-upper mil-up">Our process</h2>
                  </div>
                </div>
              </div>

              <div className="swiper-container mil-process-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="row">
                      <div className="col-lg-4">
                        <div
                          className="mil-process-box mil-icon-box mil-up mil-mb-60"
                          data-swiper-parallax-duration="400"
                          data-swiper-parallax="-900"
                          data-swiper-parallax-scale=".8"
                          data-swiper-parallax-opacity="0"
                        >
                          <div className="mil-icon mil-icon-border mil-mb-30">
                            <img src="img/icons/11.svg" alt="icon" />
                          </div>
                          <h4 className="mil-upper mil-mb-30">We Hear</h4>
                          <p>
                            Our work speaks volumes about our dedication to
                            listening, understanding, and exceeding client
                            expectations through impeccable craftsmanship and
                            innovative solutions.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div
                          className="mil-process-box mil-icon-box mil-up mil-mb-60"
                          data-swiper-parallax-duration="500"
                          data-swiper-parallax="-900"
                          data-swiper-parallax-scale=".8"
                          data-swiper-parallax-opacity="0"
                        >
                          <div className="mil-icon mil-icon-border mil-mb-30">
                            <img src="img/icons/11.svg" alt="icon" />
                          </div>
                          <h4 className="mil-upper mil-mb-30">We Give Ideas</h4>
                          <p>
                            We excel in generating creative ideas tailored to
                            each project, offering innovative solutions that
                            elevate spaces and exceed expectations.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div
                          className="mil-process-box mil-icon-box mil-up mil-mb-60"
                          data-swiper-parallax-duration="600"
                          data-swiper-parallax="-900"
                          data-swiper-parallax-scale=".8"
                          data-swiper-parallax-opacity="0"
                        >
                          <div className="mil-icon mil-icon-border mil-mb-30">
                            <img src="img/icons/11.svg" alt="icon" />
                          </div>
                          <h4 className="mil-upper mil-mb-30">
                            We Plan the Bases
                          </h4>
                          <p>
                            Our process begins with meticulous planning, laying
                            the foundation for innovative ideas that shape
                            exceptional solutions tailored to your needs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {browserName === "Safari" ? (
            <SwiperComponent2 />
          ) : (
            <section>
              <div className="container mil-p-0-30">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-5">
                    <div className="mil-mb-60">
                      <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                        Our Company
                      </span>
                      <h2 className="mil-upper mil-up mil-mb-30">
                        We provide services{" "}
                        <span className="mil-marker">and</span> solutions
                      </h2>
                      <p className="mil-up mil-mb-40">
                        At ModFur Industry, we offer comprehensive services and
                        innovative solutions tailored to your needs. From
                        conceptualization to execution, our team ensures
                        excellence every step of the way, delivering exceptional
                        results that transform spaces and exceed expectations.
                      </p>

                      <div className="row align-items-center mil-up">
                        <div className="col-lg-6 mil-mb-30">
                          <h5 className="mil-upper mil-mb-10">Sadiq Khan</h5>
                          <p>Director at ModFur Industries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mil-illustration-slider-frame mil-up mil-mb-90">
                      <div className="swiper-container mil-illustration-slider">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div
                              className="mil-illustration"
                              data-swiper-parallax-x="50"
                              data-swiper-parallax-scale="1.3"
                            >
                              <div className="mil-image-frame">
                                <img src={AboutImage.slide5} alt="image5" />
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div
                              className="mil-illustration"
                              data-swiper-parallax-x="50"
                              data-swiper-parallax-scale="1.3"
                            >
                              <div className="mil-image-frame">
                                <img src={AboutImage.slide4} alt="image4" />
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div
                              className="mil-illustration"
                              data-swiper-parallax-x="50"
                              data-swiper-parallax-scale="1.3"
                            >
                              <div className="mil-image-frame">
                                <img src={AboutImage.slide1} alt="image1" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mil-illustration-slider-nav mil-up">
                        <div className="mil-nav-buttons">
                          <div className="mil-slider-button mil-illustration-prev">
                            Prev
                          </div>
                          <div className="mil-slider-button mil-illustration-next">
                            Next
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <Testimonial />
        </div>
      </div>
    </>
  );
};

export default About;
