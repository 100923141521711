import React, { useEffect, useRef, useState } from "react";
import getBrowserInfo from "../../../components/BrowserInfoHook";
import { testimonialData } from "../../../utils/Constants/dataConstant";

const Testimonial = () => {
  const { browserName } = getBrowserInfo();
  const [currentIndex, setCurrentIndex] = useState(0);
  const reviewRef: any = useRef(null);
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : testimonialData.length - 1
    );
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < testimonialData.length - 1 ? prevIndex + 1 : 0
    );
  };
  useEffect(() => {
    if (reviewRef.current) {
      reviewRef.current.classList.remove("slide-in-right");
      // Force reflow to restart the animation
      void reviewRef.current.offsetWidth;
      reviewRef.current.classList.add("slide-in-right");
    }
  }, [currentIndex]);
  return (
    <section className="mil-soft-bg mil-relative">
      <img src="img/other/bg.svg" className="mil-bg-img" alt="image" />
      <div className="container mil-p-120-120">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <div className="mil-mb-60">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                Testimonials
              </span>
              <h2 className="mil-upper mil-up">What they think of us</h2>
            </div>
          </div>
          {browserName !== "Safari" && (
            <div className="col-lg-7 mil-mt-suptitle-offset">
              <div className="swiper-container mil-reviews-slider mil-mb-30">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div
                      className="mil-review-frame mil-mb-30"
                      data-swiper-parallax-x="-200"
                      data-swiper-parallax-opacity="0"
                    >
                      <div className="mil-reviev-head mil-up">
                        <div className="mil-left">
                          <div className="mil-quote">
                            <img src="img/icons/12.svg" alt="icon" />
                          </div>
                        </div>
                        <div className="mil-name">
                          <h6 className="mil-upper">ARUN KAPOOR</h6>
                          <p className="mil-text-sm mil-dark-soft">
                            Business Owner
                          </p>
                        </div>
                      </div>
                      <div className="mil-up">
                        <p
                          className="mil-review-text"
                          data-swiper-parallax="-60"
                        >
                          <q>
                            ModFur Industries transformed our office space with
                            their premium furniture. Their attention to detail
                            and commitment to quality are unmatched. The new
                            office setup has significantly boosted our
                            productivity and aesthetics. We couldn't be happier
                            with the results.
                          </q>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div
                      className="mil-review-frame mil-mb-30"
                      data-swiper-parallax-x="-200"
                      data-swiper-parallax-opacity="0"
                    >
                      <div className="mil-reviev-head mil-up">
                        <div className="mil-left">
                          <div className="mil-quote">
                            <img src="img/icons/12.svg" alt="icon" />
                          </div>
                        </div>
                        <div className="mil-name">
                          <h6 className="mil-upper">PRIYA MEHTA</h6>
                          <p className="mil-text-sm mil-dark-soft">
                            Interior Designer
                          </p>
                        </div>
                      </div>
                      <div className="mil-up">
                        <p
                          className="mil-review-text"
                          data-swiper-parallax="-60"
                        >
                          <q>
                            Working with ModFur Industries was a delightful
                            experience. They delivered custom modular kitchen
                            furniture that perfectly matched our client's
                            vision. Their craftsmanship and service are truly
                            top-notch, making the kitchen a highlight of the
                            home.
                          </q>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div
                      className="mil-review-frame mil-mb-30"
                      data-swiper-parallax-x="-200"
                      data-swiper-parallax-opacity="0"
                    >
                      <div className="mil-reviev-head mil-up">
                        <div className="mil-left">
                          <div className="mil-quote">
                            <img src="img/icons/12.svg" alt="icon" />
                          </div>
                        </div>
                        <div className="mil-name">
                          <h6 className="mil-upper">RAHUL SHARMA</h6>
                          <p className="mil-text-sm mil-dark-soft">
                            Project Manager
                          </p>
                        </div>
                      </div>
                      <div className="mil-up">
                        <p
                          className="mil-review-text"
                          data-swiper-parallax="-60"
                        >
                          <q>
                            ModFur Industries exceeded our expectations in every
                            way. The office furniture they provided is both
                            stylish and functional, creating a professional and
                            comfortable environment. Highly recommend their
                            services to anyone looking to enhance their
                            workspace
                          </q>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div
                      className="mil-review-frame mil-mb-30"
                      data-swiper-parallax-x="-200"
                      data-swiper-parallax-opacity="0"
                    >
                      <div className="mil-reviev-head mil-up">
                        <div className="mil-left">
                          <div className="mil-quote">
                            <img src="img/icons/12.svg" alt="icon" />
                          </div>
                        </div>
                        <div className="mil-name">
                          <h6 className="mil-upper">SUNITA TIWARI</h6>
                          <p className="mil-text-sm mil-dark-soft">Homeowner</p>
                        </div>
                      </div>
                      <div className="mil-up">
                        <p
                          className="mil-review-text"
                          data-swiper-parallax="-60"
                        >
                          <q>
                            From design to installation, ModFur Industries
                            provided an outstanding experience. Their modular
                            kitchen units are both beautiful and practical,
                            making our kitchen the heart of our home. Their
                            dedication to quality and detail is impressive.
                          </q>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mil-nav-buttons mil-reviews-nav mil-up">
                <div className="mil-slider-button mil-process-prev">Prev</div>
                <div className="mil-slider-button mil-process-next">Next</div>
              </div>
            </div>
          )}
          {browserName === "Safari" && (
            <div className="col-lg-7 mil-mt-suptitle-offset">
              <div className="swiper-container mil-reviews-slider mil-mb-30">
                <div className="swiper-wrapper">
                  {testimonialData
                    .slice(currentIndex, currentIndex + 1)
                    .map((review: any, index: any) => (
                      <div
                        className="swiper-slide active"
                        key={index}
                        ref={reviewRef}
                      >
                        <div
                          className="mil-review-frame mil-mb-30"
                          data-swiper-parallax-x="-200"
                          data-swiper-parallax-opacity="0"
                        >
                          <div className="mil-reviev-head mil-up">
                            <div className="mil-left">
                              <div className="mil-quote">
                                <img src="img/icons/12.svg" alt="icon" />
                              </div>
                            </div>
                            <div className="mil-name">
                              <h6 className="mil-upper">
                                {review.userName.toUpperCase()}
                              </h6>
                              <p className="mil-text-sm mil-dark-soft">
                                {review.position.toUpperCase()}
                              </p>
                            </div>
                          </div>
                          <div className="mil-up">
                            <p
                              className="mil-review-text"
                              data-swiper-parallax="-60"
                            >
                              <q>{review.description}</q>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="mil-nav-buttons mil-reviews-nav mil-up">
                <div
                  onClick={handlePrev}
                  className="mil-slider-button mil-process-prev"
                >
                  Prev
                </div>
                <div
                  onClick={handleNext}
                  className="mil-slider-button mil-process-next"
                >
                  Next
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
