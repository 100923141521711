import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { JobsData } from "../../utils/Constants/dataConstant";
import Banner from "../../components/Banner";

const Career = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavBar pageName="Career" />
      <div className="mil-wrapper">
        <div id="content">
          <Banner bannerName="Explore Career Opportunities" />
          <section>
            <div className="container mil-p-120-60">
              <div className="row justify-content-between">
                <div className="col-lg-12 col-sm-12 d-lg-flex row justify-content-lg-between">
                  {JobsData?.map((item: any) => {
                    return (
                      <div className="col-lg-6 col-sm-6">
                        <span
                          onClick={() =>
                            navigate("/careerDetail", {
                              state: item,
                            })
                          }
                          className="mil-blog-card mil-mb-60"
                        >
                          <div className="mil-cover mil-square mil-up">
                            <img src={item?.jobImg} alt="cover" />
                          </div>
                          <div className="mil-description">
                            <span className="mil-suptitle mil-upper mil-up">
                              {item?.jobTitle}
                            </span>
                            <h4 className="mil-upper mil-up">
                              {item?.requiredExperience} Year Experience
                            </h4>
                            <p className="mil-up">
                              {item?.jobDescription?.length > 66
                                ? `${item?.jobDescription?.slice(0, 66)}...`
                                : item?.jobDescription}
                            </p>
                            <span className="mil-link mil-upper mil-up">
                              View Detail
                              <span className="mil-arrow">
                                <img src="img/icons/1.svg" alt="arrow" />
                              </span>
                            </span>
                          </div>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Career;
