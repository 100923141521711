export const HomeImage = {
  homeBanner1: require("../Images/Home/homeBanner1.jpg"),
  homeBanner2: require("../Images/Home/homeBanner2.jpg"),
  homeBanner3: require("../Images/Home/homeBanner3.jpg"),
  homeFurniture: require("../Images/Home/homeFurniture.jpg"),
  cover1: require("../Images/Home/cover1.jpg"),
  cover2: require("../Images/Home/cover2.jpg"),
  cover3: require("../Images/Home/cover3.jpg"),
  cover4: require("../Images/Home/cover4.jpg"),
  cover5: require("../Images/Home/cover5.jpg"),
  cover6: require("../Images/Home/cover6.jpg"),
};
export const HeaderImage = {
  header: require("../Images/Header/header.jpg"),
};
export const AboutImage = {
  slide1: require("../Images/About/slide1.jpg"),
  slide2: require("../Images/About/slide2.jpg"),
  slide3: require("../Images/About/slide3.jpg"),
  slide4: require("../Images/About/slide4.jpg"),
  slide5: require("../Images/About/slide5.jpg"),
};
export const CareerImage = {
  career1: require("../Images/Career/career1.jpg"),
  career2: require("../Images/Career/career2.jpg"),
  career3: require("../Images/Career/career3.jpg"),
  career4: require("../Images/Career/career4.jpg"),
};
export const ServiceImage = {
  serviceImg: require("../Images/Service/service.jpg"),
};
export const ProductImage = {
  kitchen1: require("../Images/Product/Kitchen/l_shaped_kitchen.jpeg"),
  kitchen2: require("../Images/Product/Kitchen/u_shaped_kitchen.jpeg"),
  kitchen3: require("../Images/Product/Kitchen/parallel_kitchen.jpeg"),
  kitchen4: require("../Images/Product/Kitchen/island_kitchen.jpeg"),
  kitchen5: require("../Images/Product/Kitchen/modular_kitchen.jpeg"),
  kitchen6: require("../Images/Product/Kitchen/open_plan_kitchen.jpeg"),
  kitchen7: require("../Images/Product/Kitchen/compact_kitchen.jpeg"),
  kitchen8: require("../Images/Product/Kitchen/gourmet_kitchen.jpeg"),
  office1: require("../Images/Product/Office/office_desk.jpeg"),
  office2: require("../Images/Product/Office/office_seating.jpeg"),
  office3: require("../Images/Product/Office/meeting_tables.jpeg"),
  office4: require("../Images/Product/Office/cantilever_desks.jpeg"),
  office5: require("../Images/Product/Office/storage_wall.jpeg"),
  office6: require("../Images/Product/Office/conference_table.jpeg"),
  office7: require("../Images/Product/Office/collaborative_workstation.jpeg"),
};
export const ClientImage = {
  client1: require("../Images/Clients/client1.jpg"),
  client2: require("../Images/Clients/client2.png"),
  client3: require("../Images/Clients/client3.png"),
  client4: require("../Images/Clients/client4.png"),
  client5: require("../Images/Clients/client5.jpeg"),
  client6: require("../Images/Clients/client6.png"),
  client7: require("../Images/Clients/client7.jpeg"),
  client8: require("../Images/Clients/client8.jpeg"),
  client9: require("../Images/Clients/client9.jpeg"),
  client10: require("../Images/Clients/client10.png"),
  client11: require("../Images/Clients/client11.png"),
  client12: require("../Images/Clients/client12.png"),
};
export const LogoImage = {
  logo: require("../Images/Logo/logo2.png"),
};
export const JobsData = [
  {
    _id: 1,
    jobTitle: "CARPENTER",
    requiredExperience: 3,
    jobDescription:
      "We are seeking a skilled and experienced Carpenter to join our team. Your craftsmanship will contribute to the creation of high-quality office and modular kitchen furniture.",
    jobImg: CareerImage.career1,
  },
  {
    _id: 2,
    jobTitle: "KITCHEN FURNITURE DESIGNER",
    requiredExperience: 5,
    jobDescription:
      "Join our team as a Kitchen Furniture Designer. Your experience and creativity will help us craft beautiful and functional modular kitchen solutions.",
    jobImg: CareerImage.career2,
  },
  {
    _id: 3,
    jobTitle: "SALES MANAGER",
    requiredExperience: 5,
    jobDescription:
      "We are looking for an experienced Sales Manager to lead our sales team. Your ability to develop and execute sales strategies will drive our business growth.",
    jobImg: CareerImage.career3,
  },
  {
    _id: 4,
    jobTitle: "PROJECT MANAGER",
    requiredExperience: 8,
    jobDescription:
      "We are seeking a Project Manager to oversee our furniture projects. Your leadership and project management skills will ensure timely and successful project completion.",
    jobImg: CareerImage.career4,
  },
];

export const ProjectTypeData = [
  {
    _id: 1,
    title: "Partitions",
    link: "partitions",
  },
];
export const ServiceData = [
  {
    _id: 1,
    title: "Partitions",
    desc: "Partitions allowed",
    data: [
      { _id: 1, title: "Flush Frameless" },
      { _id: 2, title: "Flush Bonded" },
      { _id: 3, title: "Frameless" },
      { _id: 4, title: "Framed" },
      { _id: 5, title: "Modular Crosswall" },
      { _id: 6, title: "Integrated" },
      { _id: 7, title: "Curved" },
      { _id: 8, title: "Free standing" },
      { _id: 9, title: "Dry lining" },
      { _id: 10, title: "Green Walls" },
      { _id: 11, title: "Joinery" },
    ],
  },
  {
    _id: 2,
    title: "Furniture",
    desc: "Furniture allowed",
    data: [
      { _id: 1, title: "Cantilever Desks" },
      { _id: 2, title: "Office Desks" },
      { _id: 3, title: "Office Seating" },
      { _id: 4, title: "Soft seating" },
      { _id: 5, title: "Meeting Tables" },
      { _id: 6, title: "Storage" },
      { _id: 7, title: "Storagewall" },
      { _id: 8, title: "Reception Furniture" },
      { _id: 9, title: "Theatre Seating" },
    ],
  },
  {
    _id: 3,
    title: "Kitchen",
    desc: "Kitchen allowed",
    data: [
      { _id: 1, title: "Cantilever Desks" },
      { _id: 2, title: "Office Desks" },
      { _id: 3, title: "Office Seating" },
      { _id: 4, title: "Soft seating" },
      { _id: 5, title: "Meeting Tables" },
      { _id: 6, title: "Storage" },
      { _id: 7, title: "Storagewall" },
      { _id: 8, title: "Reception Furniture" },
      { _id: 9, title: "Theatre Seating" },
    ],
  },
];
export const productData = [
  {
    id: 1,
    category: "kitchen",
    image: ProductImage.kitchen1,
    alt: "L-Shaped Kitchen",
    title: "L-Shaped Kitchen",
    description:
      "Sleek and space-efficient L-shaped kitchens for modern homes.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 2,
    category: "kitchen",
    image: ProductImage.kitchen2,
    alt: "U-Shaped Kitchen",
    title: "U-Shaped Kitchen",
    description:
      "Premium U-shaped kitchens offering maximum workspace and storage.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 3,
    category: "kitchen",
    image: ProductImage.kitchen3,
    alt: "Parallel Kitchens",
    title: "Parallel Kitchens",
    description: "Stylish parallel kitchens for efficient workflow and design.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 4,
    category: "kitchen",
    image: ProductImage.kitchen4,
    alt: "Island Kitchens",
    title: "Island Kitchens",
    description:
      "Contemporary island kitchens with added workspace and seating.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 5,
    category: "kitchen",
    image: ProductImage.kitchen5,
    alt: "Modular Kitchen",
    title: "Modular Kitchen",
    description: "Versatile modular kitchens for customized solutions.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 6,
    category: "kitchen",
    image: ProductImage.kitchen6,
    alt: "Open Plan Kitchen",
    title: "Open Plan Kitchen",
    description: "Modern open-plan kitchens for seamless living spaces.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 7,
    category: "kitchen",
    image: ProductImage.kitchen7,
    alt: "Compact Kitchen",
    title: "Compact Kitchen",
    description:
      "Efficient compact kitchens for smaller spaces without compromising style.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 8,
    category: "kitchen",
    image: ProductImage.kitchen8,
    alt: "Gourmet Kitchens",
    title: "Gourmet Kitchens",
    description: "Luxurious gourmet kitchens for culinary enthusiasts.",
    type: "Kitchen",
    size: "mil-square-item",
  },
  {
    id: 9,
    category: "furniture",
    image: ProductImage.office1,
    alt: "Office Desks",
    title: "Office Desks",
    description:
      "Stylish and functional desks to enhance productivity. Choose from executive, ergonomic, and classic designs.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
  {
    id: 10,
    category: "furniture",
    image: ProductImage.office2,
    alt: "Office Seating",
    title: "Office Seating",
    description:
      "Comfortable and supportive chairs, including ergonomic, executive, and conference styles for any office environment.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
  {
    id: 11,
    category: "furniture",
    image: ProductImage.office3,
    alt: "Meeting Tables",
    title: "Meeting Tables",
    description:
      "Versatile tables designed for effective collaboration, available in various sizes and finishes to suit your needs.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
  {
    id: 12,
    category: "furniture",
    image: ProductImage.office4,
    alt: "Cantilever Desks",
    title: "Cantilever Desks",
    description:
      "Modern and sturdy desks with sleek lines, perfect for contemporary office spaces.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
  {
    id: 13,
    category: "furniture",
    image: ProductImage.office5,
    alt: "Storagewall",
    title: "Storagewall",
    description:
      "Customizable storage solutions to keep your office organized and clutter-free, maximizing efficiency.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
  {
    id: 14,
    category: "furniture",
    image: ProductImage.office6,
    alt: "Conference Tables",
    title: "Conference Tables",
    description:
      "Elegant, high-quality tables crafted for important meetings, available in various shapes and sizes.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
  {
    id: 15,
    category: "furniture",
    image: ProductImage.office7,
    alt: "Collaborative Workstations",
    title: "Collaborative Workstations",
    description:
      "Dynamic workstations designed to foster teamwork and creativity, with shared surfaces and modular components.",
    type: "Office Furniture",
    size: "mil-long-item",
  },
];
export const ClientData = [
  { _id: 1, title: "Adobe Interio Pvt Ltd", img: ClientImage.client1 },
  { _id: 2, title: "Aerify India Pvt Ltd", img: ClientImage.client2 },
  { _id: 3, title: "Crystal Air Systems", img: ClientImage.client3 },
  { _id: 4, title: "Delhivery", img: ClientImage.client4 },
  { _id: 5, title: "RBS", img: ClientImage.client5 },
  { _id: 6, title: "Coworks", img: ClientImage.client6 },
  { _id: 7, title: "Prime Honda", img: ClientImage.client7 },
  { _id: 8, title: "Pepsico", img: ClientImage.client8 },
  { _id: 9, title: "Aon", img: ClientImage.client9 },
  { _id: 10, title: "Swiggy", img: ClientImage.client10 },
  { _id: 11, title: "Siemens", img: ClientImage.client11 },
  { _id: 12, title: "Gaya Central University", img: ClientImage.client12 },
];
export const testimonialData: any = [
  {
    _id: 1,
    userName: "Arun Kapoor",
    position: "Business Owner",
    description:
      "ModFur Industries transformed our office space with their premium furniture. Their attention to detail and commitment to quality are unmatched. The new office setup has significantly boosted our productivity and aesthetics. We couldn't be happier with the results.",
  },
  {
    _id: 2,
    userName: "Priya Mehta",
    position: "Interior Designer",
    description:
      "Working with ModFur Industries was a delightful experience. They delivered custom modular kitchen furniture that perfectly matched our client's vision. Their craftsmanship and service are truly top-notch, making the kitchen a highlight of the home.",
  },
  {
    _id: 3,
    userName: "Rahul Sharma",
    position: "Project Manager",
    description:
      "ModFur Industries exceeded our expectations in every way. The office furniture they provided is both stylish and functional, creating a professional and comfortable environment. Highly recommend their services to anyone looking to enhance their workspace",
  },
  {
    _id: 4,
    userName: "Sunita Tiwari",
    position: "Homeowner",
    description:
      "From design to installation, ModFur Industries provided an outstanding experience. Their modular kitchen units are both beautiful and practical, making our kitchen the heart of our home. Their dedication to quality and detail is impressive.",
  },
];
