import { HeaderImage } from "../utils/Constants/dataConstant";
import Image from "./Image";

interface Props {
  bannerName: string;
}

const Banner = ({ bannerName }: Props) => {
  return (
    <section className="mil-banner mil-banner-sm">
      <Image
        src={HeaderImage.header}
        alt="image"
        className="mil-bg-img mil-scale"
        dataValue1=".4"
        dataValue2="1.4"
      />
      <div className="mil-overlay"></div>
      <div className="container">
        <div className="mil-banner-content mil-center">
          <div className="mil-mb-90">
            <h1 className="mil-light mil-upper mil-mb-30">{bannerName}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
