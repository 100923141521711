import Banner from "../../components/Banner";
import NavBar from "../../components/NavBar";
import { ClientData } from "../../utils/Constants/dataConstant";
import "./style.css";

const Clients = () => {
  return (
    <>
      <NavBar pageName="Client" />
      <div className="mil-wrapper">
        <div id="content">
          <Banner bannerName="Our Clients" />
          <section>
            <div className="container mil-p-120-60">
              <div className="row">
                {ClientData?.map((item: any, index: number) => (
                  <div key={index} className="col-sm-6 col-lg-3 mil-up">
                    <div className="mil-team-member2">
                      <div className="mil-avatar2 mil-mb-30">
                        <img src={item?.img} alt="Team Member" />
                      </div>
                      <h6 className="mil-upper mil-mb-10">{item?.title}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Clients;
