import { useState } from "react";
import NavBar from "../../components/NavBar";
import { productData } from "../../utils/Constants/dataConstant";
import Banner from "../../components/Banner";

const Project = () => {
  const [selectedFilter, setSelectedFilter] = useState("*");

  const handleFilterClick = (filter: any) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      <NavBar pageName="Project" />
      <div id="content">
        <Banner bannerName="Our Products" />
        <section>
          <div className="container mil-p-120-120">
            <div className="mil-center">
              <p className="mil-text-lg mil-up mil-mb-90">
                Our Projects harness design and technology to create places
                where <br /> people live, work, and heal.
              </p>
            </div>
            <div className="mil-filter mil-up mil-mb-90">
              <div className="mil-filter-links">
                <a
                  href="#"
                  data-filter="*"
                  className={selectedFilter === "*" ? "mil-current" : ""}
                  onClick={() => handleFilterClick("*")}
                >
                  All
                </a>
                <a
                  href="#"
                  data-filter=".furniture"
                  className={
                    selectedFilter === "furniture" ? "mil-current" : ""
                  }
                  onClick={() => handleFilterClick("furniture")}
                >
                  Office Furniture
                </a>
                <a
                  href="#"
                  data-filter=".kitchen"
                  className={selectedFilter === "kitechen" ? "mil-current" : ""}
                  onClick={() => handleFilterClick("kitchen")}
                >
                  Modular Kitchen
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="mil-portfolio-grid mil-up">
                  <div className="grid-sizer"></div>
                  {productData
                    .filter(
                      (project) =>
                        selectedFilter === "*" ||
                        project.category.includes(selectedFilter)
                    )
                    .map((project) => (
                      <div
                        key={project.id}
                        className={`mil-grid-item ${project.category}`}
                      >
                        <a
                          href="#"
                          className={`mil-portfolio-item-2 ${project.size} mil-mb-30`}
                        >
                          <img src={project.image} alt={project.alt} />
                          <div className="mil-project-descr">
                            <h3 className="mil-upper mil-mb-30">
                              {project.title}
                            </h3>
                            <div className="mil-link mil-upper">
                              {project.description}
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Project;
