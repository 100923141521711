import NavBar from "../../components/NavBar";
import { useLocation } from "react-router-dom";

const CareerDetails = () => {
  const location = useLocation();
  const { jobTitle, requiredExperience, jobDescription, jobImg } =
    location.state;
  return (
    <>
      <NavBar />
      <div className="mil-wrapper">
        <div id="content">
          <section className="mil-soft-bg mil-team-member-wrapper">
            <div className="container mil-p-0-120">
              <div className="mil-team-member-frame">
                <div className="mil-left-side">
                  <div className="mil-member-portrait">
                    <img src={jobImg} alt="portrait" />
                  </div>
                </div>
                <div className="mil-right-side">
                  <div className="mil-p-30-30">
                    <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                      {jobTitle}
                    </span>
                    <ul className="mil-list mil-dark mil-up mil-mb-30">
                      <li>Experience: {requiredExperience} Year</li>
                    </ul>
                    <div className="mil-divider-lg mil-up mil-mb-30"></div>
                    <p className="mil-up mil-text-sm mil-up mil-mb-30">
                      {jobDescription}
                    </p>
                    <p>
                      Submit your resume to{" "}
                      <a
                        className="text-primary"
                        rel="noreferrer"
                        target="_blank"
                        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@modfur.in"
                      >
                        info@modfur.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
