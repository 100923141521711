const getBrowserInfo = () => {
  const { userAgent } = navigator;
  let match =
    userAgent.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];
  let temp;
  let browserName = "";
  let version = "";

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    browserName = "IE";
    version = temp[1] || "";
  } else if (match[1] === "Chrome") {
    temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (temp != null) {
      browserName = temp[1].replace("OPR", "Opera");
      version = temp[2];
    } else {
      browserName = match[1];
      version = match[2];
    }
  } else {
    match = match[2]
      ? [match[1], match[2]]
      : [navigator.appName, navigator.appVersion, "-?"];
    temp = userAgent.match(/version\/(\d+)/i);
    if (temp != null) {
      match.splice(1, 1, temp[1]);
    }
    browserName = match[0];
    version = match[1];
  }

  return {
    browserName,
    version,
  };
};

export default getBrowserInfo;
