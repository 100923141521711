import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import "./style.css";
import { AboutImage } from "../../../utils/Constants/dataConstant";

SwiperCore.use([Navigation, EffectFade]);

const SwiperComponent = () => {
  return (
    <section>
      <div className="container mil-p-0-30">
        <div className="row justify-content-between align-items-center flex-sm-row-reverse">
          <div className="col-lg-5">
            <div className="mil-mb-60">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                Work
              </span>
              <h2 className="mil-upper mil-up mil-mb-30">What We Have Done</h2>
              <p className="mil-up mil-mb-40">
                Our extensive portfolio features a wide array of projects,
                ranging from residential homes to commercial complexes,
                demonstrating our versatility, craftsmanship, and dedication to
                client satisfaction.
              </p>
              <div className="mil-up">
                <a href="/product" className="mil-link mil-upper">
                  See Products
                  <span className="mil-arrow">
                    <img src="img/icons/1.svg" alt="arrow" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mil-illustration-slider-frame mil-up mil-mb-90">
              <Swiper
                className="mil-illustration-slider"
                navigation={{
                  nextEl: ".mil-illustration-next",
                  prevEl: ".mil-illustration-prev",
                }}
                parallax={true}
                spaceBetween={30}
                slidesPerView={1}
              >
                <SwiperSlide>
                  <div
                    className="mil-illustration"
                    data-swiper-parallax-x="50"
                    data-swiper-parallax-scale="1.3"
                  >
                    <div className="mil-image-frame">
                      <img src={AboutImage.slide1} alt="image1" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mil-illustration"
                    data-swiper-parallax-x="50"
                    data-swiper-parallax-scale="1.3"
                  >
                    <div className="mil-image-frame">
                      <img src={AboutImage.slide2} alt="image2" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mil-illustration"
                    data-swiper-parallax-x="50"
                    data-swiper-parallax-scale="1.3"
                  >
                    <div className="mil-image-frame">
                      <img src={AboutImage.slide3} alt="image3" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="mil-illustration-slider-nav mil-up">
                <div className="mil-nav-buttons">
                  <div className="mil-slider-button mil-illustration-prev">
                    Prev
                  </div>
                  <div className="mil-slider-button mil-illustration-next">
                    Next
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SwiperComponent;
