import { useEffect, useState, memo } from "react";
import HomeBanner from "../../../components/HomeBanner";
import CountUp from "react-countup";
import { HomeImage } from "../../../utils/Constants/dataConstant";

const HeroSection = () => {
  const images = [
    HomeImage.homeBanner1,
    HomeImage.homeBanner2,
    HomeImage.homeBanner3,
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(slideInterval);
  }, [images.length]);

  return (
    <section className="mil-banner">
      <HomeBanner img={images[currentSlide]} />
      <div className="mil-overlay"></div>
      <div className="container">
        <div className="mil-banner-content">
          <div className="row align-items-end">
            <div className="col-xl-7">
              <div className="mil-mb-90">
                <span className="mil-suptitle mil-light mil-upper mil-mb-60">
                  The future
                </span>
                <h1 className="mil-upper mil-light mil-mb-60">
                  Modern
                  <br />
                  <span className="mil-accent">Furniture</span>
                  <br />
                  Craftsmanship
                </h1>
                <a href="/product" className="mil-link mil-light mil-upper">
                  View Products{" "}
                  <span className="mil-arrow mil-custom-color-bg">
                    <img src="img/icons/1.svg" alt="arrow" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="row mil-mb-60">
                <div className="col-6">
                  <div className="mil-counter-frame mil-light mil-mb-30">
                    <h4 className="mil-accent mil-thin mil-mb-10">
                      <CountUp
                        className="mil-counter"
                        start={0}
                        end={346}
                        duration={2}
                        decimals={0}
                      />
                      {/* <span className="mil-counter" data-number="346">
                          0
                        </span> */}
                      +
                    </h4>
                    <p className="mil-light">
                      Succeeded <br />
                      Projects
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mil-counter-frame mil-light mil-mb-30">
                    <h4 className="mil-accent mil-thin mil-mb-10">
                      <CountUp start={0} end={9} duration={2} decimals={0} />
                      {/* <span className="mil-counter" data-number="9">
                        0
                      </span> */}
                      k+
                    </h4>
                    <p className="mil-light">
                      Working <br />
                      Hours
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mil-counter-frame mil-light mil-mb-30">
                    <h4 className="mil-accent mil-thin mil-mb-10">
                      <CountUp start={0} end={25} duration={2} decimals={0} />
                      {/* <span className="mil-counter" data-number="25">
                        0
                      </span> */}
                      +
                    </h4>
                    <p className="mil-light">
                      Years <br />
                      Experience
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mil-counter-frame mil-light mil-mb-30">
                    <h4 className="mil-accent mil-thin mil-mb-10">
                      <CountUp start={0} end={99} duration={2} decimals={0} />
                      {/* <span className="mil-counter" data-number="99">
                        0
                      </span> */}
                      +
                    </h4>
                    <p className="mil-light">
                      Clients <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(HeroSection);
