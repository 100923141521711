const OurAdvantageSection = () => {
  return (
    <section>
      <div className="container mil-p-120-60">
        <div className="row">
          <div className="col-12">
            <div className="mil-center mil-mb-90">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                Our Advantages
              </span>
              <h2 className="mil-upper mil-up mil-mb-30">
                We Take Care of Everything <br />
                for You!
              </h2>
              <p className="mil-up">
                Our advantage lies in innovative designs, high-quality
                craftsmanship, <br /> and unwavering commitment to customer
                satisfaction.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="mil-advantage mil-icon-box mil-center mil-up mil-mb-60">
              <h4 className="mil-upper mil-mb-30">Listen</h4>
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/6.svg" alt="icon" />
              </div>
              <p>
                We listen attentively to our clients, understanding their needs
                and aspirations deeply.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="mil-advantage mil-icon-box mil-center mil-up mil-mb-60">
              <h4 className="mil-upper mil-mb-30">Strategies</h4>
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/6.svg" alt="icon" />
              </div>
              <p>
                We strategize meticulously, devising comprehensive plans
                tailored to each project's unique requirements.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="mil-advantage mil-icon-box mil-center mil-up mil-mb-60">
              <h4 className="mil-upper mil-mb-30">Project Work</h4>
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/6.svg" alt="icon" />
              </div>
              <p>
                Our team excels in project execution, ensuring seamless
                implementation from conception to completion.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="mil-advantage mil-icon-box mil-center mil-up mil-mb-60">
              <h4 className="mil-upper mil-mb-30">Launch</h4>
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/6.svg" alt="icon" />
              </div>
              <p>
                With dedication, we navigate the complexities of launching
                initiatives, delivering successful outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurAdvantageSection;
