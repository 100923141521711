import "../../utils/css/style.css";
import "../../utils/scss/_components.scss";
import "../../utils/scss/_common.scss";
import "../../App.css";
import HeroSection from "./components/heroSection";
import MoreInfo from "./components/moreInfoSection";
import NavBar from "../../components/NavBar";
import WhatWeDoSection from "./components/whatWeDoSection";
import OurAdvantageSection from "./components/ourAdvantageSection";
import WorkPhotoSection from "./components/workPhotoSection";
import HowWeWorkSection from "./components/howWeWorkSection";

const Dashboard = () => {
  return (
    <>
      <NavBar pageName={"Home"} />
      <div id="content">
        <HeroSection />
        <MoreInfo />
        <WhatWeDoSection />
        <OurAdvantageSection />
        <WorkPhotoSection />
        <HowWeWorkSection />
      </div>
    </>
  );
};

export default Dashboard;
