import React from "react";

interface Props {
  src: string;
  alt?: string;
  className?: string;
  dataValue1?: string;
  dataValue2?: string;
  style?: any;
}

const Image = ({
  src,
  alt = "image",
  className,
  dataValue1,
  dataValue2,
  style,
}: Props) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      data-value-1={dataValue1}
      data-value-2={dataValue2}
      style={style}
    />
  );
};

export default Image;
