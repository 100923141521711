import { HomeImage } from "../../../utils/Constants/dataConstant";

const WorkPhotoSection = () => {
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <a
              href="/product"
              className="mil-portfolio-item mil-square-item mil-up mil-mb-30"
            >
              <img src={HomeImage.cover1} alt="cover" />
              <div className="mil-project-descr" style={styles.squareSizeCard}>
                <h4 className="mil-upper mil-mb-20 fs-5">
                  Kitchen architecture
                </h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="text-dark">
                  Revamp your kitchen with elegant decor and functional
                  furnishings today!
                </p>
              </div>
            </a>

            <a
              href="/product"
              className="mil-portfolio-item mil-square-item mil-up mil-mb-30"
            >
              <img src={HomeImage.cover2} alt="cover" />
              <div className="mil-project-descr" style={styles.squareSizeCard}>
                <h4 className="mil-upper mil-mb-20 fs-5">Kitchen castle</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="text-dark">
                  Transform your kitchen into a regal castle with luxurious
                  decorations!
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6 col-lg-3">
            <a
              href="/product"
              className="mil-portfolio-item mil-long-item mil-up mil-mb-30"
            >
              <img src={HomeImage.cover3} alt="cover" />
              <div className="mil-project-descr">
                <h4 className="mil-upper mil-mb-20">Office Meeting</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="text-dark">
                  Furnish your office meeting room for productivity and
                  professionalism today!
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6 col-lg-3">
            <a
              href="/product"
              className="mil-portfolio-item mil-square-item mil-up mil-mb-30"
            >
              <img src={HomeImage.cover4} alt="cover" />
              <div className="mil-project-descr" style={styles.squareSizeCard}>
                <h4 className="mil-upper mil-mb-20 fs-5">Kitchen Interior</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="text-dark">
                  Elevate your kitchen with exquisite interior decorations for a
                  stylish ambiance.
                </p>
              </div>
            </a>

            <a
              href="/product"
              className="mil-portfolio-item mil-square-item mil-up mil-mb-30"
            >
              <img src={HomeImage.cover5} alt="cover" />
              <div className="mil-project-descr" style={styles.squareSizeCard}>
                <h4 className="mil-upper mil-mb-20 fs-5">Home Decor</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="text-dark">
                  Upgrade your home with stunning decor for a cozy ambiance.
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-6 col-lg-3">
            <a
              href="/product"
              className="mil-portfolio-item mil-long-item mil-up mil-mb-30"
            >
              <img src={HomeImage.cover6} alt="cover" />
              <div className="mil-project-descr">
                <h4 className="mil-upper mil-mb-20">Modern architecture</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="text-dark">
                  Embrace sleek lines and minimalist design with contemporary
                  architectural marvels.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkPhotoSection;

const styles = {
  squareSizeCard: {
    width: "90%",
    paddingLeft: 0,
    marginLeft: 15,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
};
