import React from "react";
import Image from "../../../components/Image";

const WhatWeDoSection = () => {
  return (
    <section className="mil-soft-bg mil-relative">
      <Image src="img/other/bg.svg" className="mil-bg-img" alt="image" />
      <div className="container mil-p-120-90">
        <div className="row">
          <div className="col-12">
            <div className="mil-center mil-mb-90">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                Services
              </span>
              <h2 className="mil-upper mil-up">What We Do</h2>
            </div>
          </div>
          <div className="col-lg-4">
            <a href="/service" className="mil-service-card mil-up mil-mb-30">
              <div className="mil-card-number">01.</div>
              <div className="mil-center">
                <div className="mil-icon mil-icon-lg mil-mb-30">
                  <img src="img/icons/3.svg" alt="icon" />
                </div>
                <h4 className="mil-upper mil-mb-20">OFFICE FURNITURE</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="mil-service-text">
                  Enhance your workspace with ModFur Industries premium office
                  furniture. Our products are designed to promote productivity
                  and reflect modern aesthetics, providing comforts.
                </p>
                <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                  <img src="img/icons/1.svg" alt="icon" />
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4">
            <a href="/service" className="mil-service-card mil-up mil-mb-30">
              <div className="mil-card-number">02.</div>
              <div className="mil-center">
                <div className="mil-icon mil-icon-lg mil-mb-30">
                  <img src="img/icons/5.svg" alt="icon" />
                </div>
                <h4 className="mil-upper mil-mb-20">MODULAR KITCHEN</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="mil-service-text">
                  Elevate your kitchen with ModFur Industries' stylish and
                  durable modular kitchen furniture. We craft pieces that blend
                  beauty and practicality, making your kitchen a centerpiece of
                  your home.
                </p>
                <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                  <img src="img/icons/1.svg" alt="icon" />
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4">
            <a href="/service" className="mil-service-card mil-up mil-mb-30">
              <div className="mil-card-number">03.</div>
              <div className="mil-center">
                <div className="mil-icon mil-icon-lg mil-mb-30">
                  <img src="img/icons/4.svg" alt="icon" />
                </div>
                <h4 className="mil-upper mil-mb-20">CUSTOM SOLUTION</h4>
                <div className="mil-divider-sm mil-mb-20"></div>
                <p className="mil-service-text">
                  Tailor-made furniture solutions to meet your specific needs.
                  At ModFur Industries, we offer customization options to ensure
                  that our furniture perfectly fits your unique space and style.
                </p>
                <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                  <img src="img/icons/1.svg" alt="icon" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDoSection;
