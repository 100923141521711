import { useEffect, useState } from "react";
import Image from "./Image";
import "./style/homeBannerStyle.css";

interface Props {
  img: string;
  isStyle?: Boolean;
}

const HomeBanner = ({ img, isStyle }: Props) => {
  const [currentImg, setCurrentImg] = useState(img);
  const [fadeClass, setFadeClass] = useState("fade-in");

  useEffect(() => {
    setFadeClass("fade-out");
    const timer = setTimeout(() => {
      setCurrentImg(img);
      setFadeClass("fade-in");
    }, 1000); // Adjust the duration to match the CSS transition time

    return () => clearTimeout(timer);
  }, [img]);
  return (
    <div className={`mil-bg-img mil-scale ${fadeClass}`}>
      <Image
        src={currentImg}
        alt="image"
        className="mil-bg-img mil-scale"
        dataValue1=".4"
        dataValue2="1.4"
        style={{ objectPosition: !isStyle ? "top" : "" }}
      />
    </div>
  );
};

export default HomeBanner;
