import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import "./style.css";
import { AboutImage } from "../../../utils/Constants/dataConstant";

SwiperCore.use([Navigation, EffectFade]);

const SwiperComponent2 = () => {
  return (
    <section>
      <div className="container mil-p-0-30">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-5">
            <div className="mil-mb-60">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                Our Company
              </span>
              <h2 className="mil-upper mil-up mil-mb-30">
                We provide services <span className="mil-marker">and</span>{" "}
                solutions
              </h2>
              <p className="mil-up mil-mb-40">
                At ModFur Industry, we offer comprehensive services and
                innovative solutions tailored to your needs. From
                conceptualization to execution, our team ensures excellence
                every step of the way, delivering exceptional results that
                transform spaces and exceed expectations.
              </p>

              <div className="row align-items-center mil-up">
                <div className="col-lg-6 mil-mb-30">
                  <h5 className="mil-upper mil-mb-10">Sadiq Khan</h5>
                  <p>Director at ModFur Industries</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mil-illustration-slider-frame mil-up mil-mb-90">
              <Swiper
                className="mil-illustration-slider"
                navigation={{
                  nextEl: ".mil-illustration-next",
                  prevEl: ".mil-illustration-prev",
                }}
                parallax={true}
                spaceBetween={30}
                slidesPerView={1}
              >
                <SwiperSlide>
                  <div
                    className="mil-illustration"
                    data-swiper-parallax-x="50"
                    data-swiper-parallax-scale="1.3"
                  >
                    <div className="mil-image-frame">
                      <img src={AboutImage.slide5} alt="image1" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mil-illustration"
                    data-swiper-parallax-x="50"
                    data-swiper-parallax-scale="1.3"
                  >
                    <div className="mil-image-frame">
                      <img src={AboutImage.slide4} alt="image2" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="mil-illustration"
                    data-swiper-parallax-x="50"
                    data-swiper-parallax-scale="1.3"
                  >
                    <div className="mil-image-frame">
                      <img src={AboutImage.slide1} alt="image3" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="mil-illustration-slider-nav mil-up">
                <div className="mil-nav-buttons">
                  <div className="mil-slider-button mil-illustration-prev">
                    Prev
                  </div>
                  <div className="mil-slider-button mil-illustration-next">
                    Next
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SwiperComponent2;
