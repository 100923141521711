import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "../App.css";
import Loader from "../components/Loader";
import Dashboard from "../screens/Dashboard/index";
import About from "../screens/About";
import Service from "../screens/Services";
import Project from "../screens/Projects";
import Contact from "../screens/Contact";
import Career from "../screens/Career";
import CareerDetails from "../screens/Career/careerDetails";
import Clients from "../screens/Clients";

const WebRoute = () => {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/product" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/careerDetail" element={<CareerDetails />} />
          <Route path="/client" element={<Clients />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default WebRoute;
