import Banner from "../../components/Banner";
import NavBar from "../../components/NavBar";
import { ServiceImage } from "../../utils/Constants/dataConstant";

const Service = () => {
  return (
    <>
      <NavBar pageName="Service" />
      <div id="content">
        <Banner bannerName="Our Services" />
        <section>
          <div className="container mil-p-120-90">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="mil-center mil-mb-120">
                  <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                    Modern concept
                  </span>
                  <p className="mil-text-lg mil-up">
                    The modern concept in furniture design emphasizes
                    simplicity, clean lines, and functionality. It eschews
                    ornamentation in favor of sleek, minimalist aesthetics,
                    often incorporating innovative materials and technologies.
                    Modern furniture embraces open spaces, natural light, and
                    ergonomic designs, creating environments that are both
                    visually striking and highly functional for contemporary
                    living.
                  </p>
                </div>
              </div>
            </div>

            <div className="mil-center mil-mb-90">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                WHAT WE OFFER
              </span>
              <h2 className="mil-upper mil-up">Exclusive Services</h2>
            </div>

            <div className="row">
              <div className="col-lg-4 mil-up">
                <a href="/service" className="mil-service-card mil-mb-30">
                  <div className="mil-card-number">01.</div>
                  <div className="mil-center">
                    <div className="mil-icon mil-icon-lg mil-mb-30">
                      <img src="img/icons/3.svg" alt="icon" />
                    </div>
                    <h4 className="mil-upper mil-mb-20">OFFICE FURNITURE</h4>
                    <div className="mil-divider-sm mil-mb-20"></div>
                    <p className="mil-service-text">
                      Enhance your workspace with ModFur Industries' premium
                      office furniture. Designed to boost productivity and
                      reflect modern aesthetics, our office furniture combines
                      comfort and functionality.
                    </p>
                    <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                      <img src="img/icons/1.svg" alt="icon" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mil-up">
                <a href="/service" className="mil-service-card mil-mb-30">
                  <div className="mil-card-number">02.</div>
                  <div className="mil-center">
                    <div className="mil-icon mil-icon-lg mil-mb-30">
                      <img src="img/icons/5.svg" alt="icon" />
                    </div>
                    <h4 className="mil-upper mil-mb-20">MODULAR KITCHEN</h4>
                    <div className="mil-divider-sm mil-mb-20"></div>
                    <p className="mil-service-text">
                      Elevate your kitchen with ModFur Industries' stylish and
                      practical modular kitchen solutions. Our modular kitchens
                      blend beauty and efficiency, making your kitchen the
                      centerpiece of your home.
                    </p>
                    <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                      <img src="img/icons/1.svg" alt="icon" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mil-up">
                <a href="/service" className="mil-service-card mil-mb-30">
                  <div className="mil-card-number">03.</div>
                  <div className="mil-center">
                    <div className="mil-icon mil-icon-lg mil-mb-30">
                      <img src="img/icons/4.svg" alt="icon" />
                    </div>
                    <h4 className="mil-upper mil-mb-20">CUSTOM SOLUTIONS</h4>
                    <div className="mil-divider-sm mil-mb-20"></div>
                    <p className="mil-service-text">
                      Tailor-made furniture solutions to meet your specific
                      needs. At ModFur Industries, we offer customization
                      options to ensure that our furniture perfectly fits your
                      unique space and style.
                    </p>
                    <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                      <img src="img/icons/1.svg" alt="icon" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mil-up">
                <a href="/service" className="mil-service-card mil-mb-30">
                  <div className="mil-card-number">04.</div>
                  <div className="mil-center">
                    <div className="mil-icon mil-icon-lg mil-mb-30">
                      <img src="img/icons/4.svg" alt="icon" />
                    </div>
                    <h4 className="mil-upper mil-mb-20">ERGONOMIC FURNITURE</h4>
                    <div className="mil-divider-sm mil-mb-20"></div>
                    <p className="mil-service-text">
                      Improve workplace health and comfort with our ergonomic
                      furniture solutions. Designed to reduce strain and enhance
                      productivity, our ergonomic chairs, desks, and accessories
                      support a healthier work environment.
                    </p>
                    <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                      <img src="img/icons/1.svg" alt="icon" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mil-up">
                <a href="/service" className="mil-service-card mil-mb-30">
                  <div className="mil-card-number">05.</div>
                  <div className="mil-center">
                    <div className="mil-icon mil-icon-lg mil-mb-30">
                      <img src="img/icons/3.svg" alt="icon" />
                    </div>
                    <h4 className="mil-upper mil-mb-20">STORAGE SOLUTIONS</h4>
                    <div className="mil-divider-sm mil-mb-20"></div>
                    <p className="mil-service-text">
                      Optimize your space with our innovative storage solutions.
                      From modular shelving to custom cabinetry, ModFur
                      Industries offers a variety of options to help you
                      organize your office or kitchen efficiently.
                    </p>
                    <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                      <img src="img/icons/1.svg" alt="icon" />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 mil-up">
                <a href="/service" className="mil-service-card mil-mb-30">
                  <div className="mil-card-number">06.</div>
                  <div className="mil-center">
                    <div className="mil-icon mil-icon-lg mil-mb-30">
                      <img src="img/icons/5.svg" alt="icon" />
                    </div>
                    <h4 className="mil-upper mil-mb-20">
                      FURNITURE REPAIR & MAINTENANCE
                    </h4>
                    <div className="mil-divider-sm mil-mb-20"></div>
                    <p className="mil-service-text">
                      Extend the life of your furniture with our repair and
                      maintenance services. Our skilled technicians ensure that
                      your office and kitchen furniture remain in top condition,
                      providing ongoing support and care.
                      <br />
                    </p>
                    <div className="mil-go-buton mil-icon mil-icon-lg mil-icon-accent-bg">
                      <img src="img/icons/1.svg" alt="icon" />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container mil-p-0-120">
            <div className="mil-background-grid mil-softened"></div>
            <div className="mil-center mil-mb-120">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                Good Vision
              </span>
              <h2 className="mil-upper mil-up">Our Soul</h2>
            </div>
            <div className="row mil-mb-30">
              <div className="col-lg-4 mil-up">
                <div className="mil-icon-box mil-center mil-mb-60">
                  <div className="mil-icon mil-icon-xl mil-icon-border mil-mb-30">
                    <img src="img/icons/11.svg" alt="icon" />
                  </div>
                  <h4 className="mil-upper mil-mb-30">Professionals</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mil-up">
                <div className="mil-icon-box mil-center mil-mb-60">
                  <div className="mil-icon mil-icon-xl mil-icon-border mil-mb-30">
                    <img src="img/icons/11.svg" alt="icon" />
                  </div>
                  <h4 className="mil-upper mil-mb-30">Programs</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mil-up">
                <div className="mil-icon-box mil-center mil-mb-60">
                  <div className="mil-icon mil-icon-xl mil-icon-border mil-mb-30">
                    <img src="img/icons/11.svg" alt="icon" />
                  </div>
                  <h4 className="mil-upper mil-mb-30">IT Techology</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mil-center">
              <div className="mil-center mil-up">
                <a href="blog.html" className="mil-link mil-upper">
                  See All{" "}
                  <span className="mil-arrow">
                    <img src="img/icons/1.svg" alt="arrow" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section> */}
        <section className="mil-soft-bg mil-relative">
          <img src="img/other/bg.svg" className="mil-bg-img" alt="image" />
          <div className="container mil-p-120-60">
            <div className="row">
              <div className="col-lg-4">
                <div className="div mil-mb-60">
                  <h3 className="mil-upper mil-up mil-mb-30">
                    Perfect <br />
                    Planning
                  </h3>
                  <div className="mil-divider-sm mil-up mil-mb-30"></div>
                  <p className="mil-shortened mil-up mil-mb-30">
                    Where foresight meets precision, ensuring seamless execution
                    and exceptional outcomes in every endeavor.
                  </p>
                  <ul className="mil-icon-list mil-mb-60">
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Space Optimization
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Custom Design Planning
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Workflow Efficiency Analysis
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Material Selection Guidance
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Project Management
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="div mil-mb-60">
                  <h3 className="mil-upper mil-up mil-mb-30">
                    Professional <br />
                    Design
                  </h3>
                  <div className="mil-divider-sm mil-up mil-mb-30"></div>
                  <p className="mil-shortened mil-up mil-mb-30">
                    Expertise and creativity converge to deliver tailored
                    solutions that exceed expectations and elevate spaces with
                    sophistication.
                  </p>
                  <ul className="mil-icon-list mil-mb-60">
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      3D Modeling
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Custom Furniture Design
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Material Simulation
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Ergonomic Assessments
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Aesthetic Consultation
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="div mil-mb-60">
                  <h3 className="mil-upper mil-up mil-mb-30">
                    Best Experts <br />
                    Team
                  </h3>
                  <div className="mil-divider-sm mil-up mil-mb-30"></div>
                  <p className="mil-shortened mil-up mil-mb-30">
                    Utilizing expertise and efficiency to deliver optimal
                    results within set timelines, surpassing expectations with
                    excellence.
                  </p>
                  <ul className="mil-icon-list mil-mb-60">
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Experienced Craftsmanship
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Innovative Design Techniques
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Quality Assurance
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Installation and Setup
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      Post-Installation Support
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mil-p-120-30">
            <div className="row justify-content-between align-items-center flex-sm-row-reverse">
              <div className="col-lg-5">
                <div className="mil-mb-90">
                  <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                    Our Experts
                  </span>
                  <h2 className="mil-upper mil-up mil-mb-30">
                    years of experience
                  </h2>
                  <p className="mil-up mil-mb-40">
                    Drawing from our years of expertise, we deliver unparalleled
                    solutions tailored to your needs.
                  </p>
                  <ul className="mil-icon-list mil-mb-60">
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      346+ Projects Completed
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />A Great Work Team
                      Nationwide
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      99+ Satisfied Clients
                    </li>
                    <li className="mil-up">
                      <img src="img/icons/11.svg" alt="icon" />
                      25+ Years of Industry Experience
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mil-illustration mil-up mil-mb-90">
                  <div className="mil-image-frame">
                    <img
                      src={ServiceImage.serviceImg}
                      alt="image1"
                      className="mil-scale"
                      data-value-1="1"
                      data-value-2="1.3"
                    />
                  </div>
                  {/* <div className="mil-about-counter mil-center">
                    <div className="mil-avatar mil-mb-30">
                      <img src="img/faces/1.jpg" alt="face" />
                    </div>
                    <h5 className="mil-upper mil-mb-10">Joe French</h5>
                    <p className="mil-text-sm mil-dark-soft">Project Agent</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container mil-p-0-120">
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                  Your Idea
                </span>
                <h2 className="mil-upper mil-up">
                  Interested in Our <span className="mil-marker">Work?</span>
                </h2>
              </div>
              <div className="col-lg-4 mil-mt-suptitle-offset">
                <p className="mil-up">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="col-lg-3 mil-mt-suptitle-offset">
                <div className="mil-adaptive-right mil-up">
                  <a href="contact.html" className="mil-button">
                    Let's Talk Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
};

export default Service;
