import React from "react";

const HowWeWorkSection = () => {
  return (
    <section>
      <div className="container mil-p-90-60">
        <div className="row">
          <div className="col-12">
            <div className="mil-center mil-mb-90">
              <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                How We Work
              </span>
              <h2 className="mil-upper mil-up">This is How Magic Happens</h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mil-hww mil-icon-box mil-up mil-mb-60">
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/7.svg" alt="icon" />
              </div>
              <h4 className="mil-upper mil-mb-20">High Efficiency</h4>
              <div className="mil-divider-sm mil-mb-20"></div>
              <p>
                Our work ethos prioritizes high efficiency, ensuring timely
                completion and exceptional quality in every endeavor.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mil-hww mil-icon-box mil-up mil-mb-60">
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/8.svg" alt="icon" />
              </div>
              <h4 className="mil-upper mil-mb-20">Team of Experts</h4>
              <div className="mil-divider-sm mil-mb-20"></div>
              <p>
                Collaborative teamwork drives our projects, fostering
                innovation, creativity, and synergy for exceptional results.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="mil-hww mil-icon-box mil-up mil-mb-60">
              <div className="mil-icon mil-icon-border mil-mb-30">
                <img src="img/icons/9.svg" alt="icon" />
              </div>
              <h4 className="mil-upper mil-mb-20">Technology</h4>
              <div className="mil-divider-sm mil-mb-20"></div>
              <p>
                We leverage cutting-edge technology to streamline processes and
                deliver innovative solutions with precision and efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWorkSection;
