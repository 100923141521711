import { useState, useEffect, memo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import { LogoImage } from "../utils/Constants/dataConstant";

interface Props {
  pageName?: string;
}

const NavBar = ({ pageName = "" }: Props) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  const onBtnClick = (e: any, val: string) => {
    e.preventDefault();
    if (val === "Home") {
      navigate("/");
      window.location.reload();
    } else {
      navigate(`/${val.toLowerCase()}`);
      window.location.reload();
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg ${
        isScrolled ? "navbar-dark bg-dark" : "navbar-dark bg-transparent"
      } fixed-top`}
    >
      <div className="container-fluid">
        <a href="/" className="navbar-brand">
          <img src={LogoImage.logo} alt="Logo" width={150} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
            <li className={`nav-item ${pageName === "Home" ? "active" : ""}`}>
              <a
                href="/"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "Home")}
              >
                Home
              </a>
            </li>
            <li className={`nav-item ${pageName === "About" ? "active" : ""}`}>
              <a
                href="/about"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "About")}
              >
                About
              </a>
            </li>
            <li
              className={`nav-item ${pageName === "Service" ? "active" : ""}`}
            >
              <a
                href="/service"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "Service")}
              >
                Services
              </a>
            </li>
            <li
              className={`nav-item ${pageName === "Project" ? "active" : ""}`}
            >
              <a
                href="/product"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "Product")}
              >
                Products
              </a>
            </li>
            <li className={`nav-item ${pageName === "Career" ? "active" : ""}`}>
              <a
                href="/career"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "Career")}
              >
                Careers
              </a>
            </li>
            <li className={`nav-item ${pageName === "Client" ? "active" : ""}`}>
              <a
                href="/client"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "Client")}
              >
                Clients
              </a>
            </li>
            <li
              className={`nav-item ${pageName === "Contact" ? "active" : ""}`}
            >
              <a
                href="/contact"
                className="nav-link"
                onClick={(e) => onBtnClick(e, "Contact")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default memo(NavBar);
