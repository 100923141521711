import { memo } from "react";
import "../styles/moreInfoStyles.css";
import CountUp from "react-countup";
import { HomeImage } from "../../../utils/Constants/dataConstant";

const MoreInfo = () => {
  return (
    <div className="my-5">
      <section>
        <div className="container mil-p-0-30">
          <div className="row justify-content-between align-items-center flex-sm-row-reverse">
            <div className="col-lg-5">
              <div className="mil-mb-90">
                <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                  About Industry
                </span>
                <h2 className="mil-upper mil-up mil-mb-30">
                  Transforming Spaces with Precision
                </h2>
                <p className="mil-up mil-mb-40">
                  ModFur Industries ensures an unparalleled furniture experience
                  by seamlessly combining aesthetic appeal with unmatched
                  functionality. With over 25 years of expertise in the
                  industry, our pieces are meticulously crafted from
                  high-quality materials, showcasing our dedication to
                  durability and style.
                  <br />
                  <br />
                  Our innovative approach allows us to stay ahead of trends,
                  offering modern solutions that are both practical and visually
                  stunning. Whether you are looking to elevate your home or
                  enhance your workspace, ModFur Industries delivers with
                  precision and passion. Our commitment to excellence is
                  reflected in our extensive portfolio of 346+ successful
                  projects and over 9k working hours.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mil-illustration mil-up mil-mb-90">
                <div className="mil-image-frame">
                  <img
                    src={HomeImage.homeFurniture}
                    alt="image"
                    className="mil-scale"
                    data-value1="1"
                    data-value2="1.3"
                  />
                </div>
                <div className="mil-about-counter">
                  <b
                    style={{
                      position: "absolute",
                      right: "12%",
                      fontSize: 28,
                      color: "#000000",
                    }}
                  >
                    +
                  </b>
                  <CountUp
                    className="mil-counter mil-right mil-mb-10 fw-bold"
                    style={{ fontSize: 34, marginLeft: 10, color: "#000000" }}
                    start={0}
                    end={25}
                    duration={2.75}
                  />
                  {/* <h1
                    className="mil-counter mil-right mil-mb-10"
                    data-number="25"
                  >
                    0
                  </h1> */}
                  <h5 className="mil-upper mil-right">
                    <span className="mil-marker">Years</span> of
                    <br />
                    successful <br />
                    work
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* other  */}
      <section>
        <div className="container mil-p-0-90">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div>
                <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                  Our Purpose
                </span>
                <h2 className="mil-upper mil-up mil-mb-40">
                  We Turn Ideas Into Masterpieces
                </h2>
                <a href="/about" className="mil-link mil-upper mil-up">
                  Get To Know Us
                  <span className="mil-arrow">
                    <img src="img/icons/1.svg" alt="arrow" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-5 mil-mt-suptitle-offset">
              <p className="mil-up mil-mb-60">
                At ModFur Industries, we transform visionary ideas into tangible
                masterpieces. Our innovative designs and unparalleled
                craftsmanship elevate concepts into exquisite furniture pieces
                that blend creativity with functionality. Each piece is a
                testament to our commitment to excellence and our passion for
                design.
              </p>
              <div className="row">
                <div className="col-sm-4">
                  <a
                    href="/service"
                    className="mil-icon-box mil-sm-center mil-mb-30"
                  >
                    <div className="mil-icon mil-icon-accent-bg mil-up mil-mb-30">
                      <img src="img/icons/3.svg" alt="icon" />
                    </div>
                    <h6 className="mil-upper mil-up">DESIGN</h6>
                  </a>
                </div>
                <div className="col-sm-4">
                  <a
                    href="/service"
                    className="mil-icon-box mil-sm-center mil-mb-30"
                  >
                    <div className="mil-icon mil-icon-accent-bg mil-up mil-mb-30">
                      <img src="img/icons/4.svg" alt="icon" />
                    </div>
                    <h6 className="mil-upper mil-up">CUSTOMIZATION</h6>
                  </a>
                </div>
                <div className="col-sm-4">
                  <a
                    href="/service"
                    className="mil-icon-box mil-sm-center mil-mb-30"
                  >
                    <div className="mil-icon mil-icon-accent-bg mil-up mil-mb-30">
                      <img src="img/icons/5.svg" alt="icon" />
                    </div>
                    <h6 className="mil-upper mil-up">IMPLEMENTATION</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default memo(MoreInfo);
