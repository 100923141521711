import Banner from "../../components/Banner";
import NavBar from "../../components/NavBar";

const Contact = () => {
  return (
    <>
      <NavBar pageName="Contact" />
      <div id="content">
        <Banner bannerName="Contact Us" />
        <section>
          <div className="container mil-p-120-60">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <div className="mil-mb-90">
                  <h2 className="mil-upper mil-up mil-mb-30">
                    Contact Information
                  </h2>
                  <p className="mil-up mil-mb-40">
                    For any inquiries, questions, or feedback, please don't
                    hesitate to get in touch with us. Our team at ModFur
                    Industries is here to assist you with all your furniture
                    needs and provide you with the best possible solutions.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 mil-relative">
                <div className="mil-contact-sidebar">
                  <div className="mil-map-frame mil-up rounded-5">
                    <div className="mil-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3507.441723756543!2d77.275131!3d28.466241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI3JzU4LjUiTiA3N8KwMTYnMzAuNSJF!5e0!3m2!1sen!2sin!4v1717315397857!5m2!1sen!2sin"
                        width="400"
                        height="300"
                        style={{ border: 0 }}
                        loading="lazy"
                        title="Modfur Industry"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mil-up mil-mb-60">
                      <span className="mil-suptitle mil-upper mil-up mil-mb-30">
                        ModFur Industries
                      </span>
                      <p className="mil-up">
                        Anant Compound Shed No. 5
                        <br />
                        Village Anangpur, Near St. Bridge Mohan Lal Sr. Sec.
                        School <br />
                        Faridabad, Haryana, 121003 <br /> Phone No. - + 011 406
                        12820 <br />
                        Email - info@modfur.in
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mil-relative">
          <div className="container mil-p-120-30">
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <div className="mil-mb-90">
                  <h2 className="mil-upper mil-up mil-mb-30">
                    We’d love to talk
                  </h2>
                  <p className="mil-up mil-mb-30">
                    Have a question or need assistance? We’re here to help. Send
                    us a message to start the conversation. Whether you’re
                    interested in our designs or have a project in mind, we’d
                    love to hear from you.
                  </p>
                  <div className="mil-divider-lg mil-up mil-mb-30"></div>
                  <p className="mil-up mil-mb-30">
                    Interested in joining the team? Browse our current job
                    opportunities.
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-up mil-mb-30">
                      <label className="mil-upper">
                        <span>Full Name</span>
                        <span className="mil-required">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter Your Name Here"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-up mil-mb-30">
                      <label className="mil-upper">
                        <span>Email address</span>
                        <span className="mil-required">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Your Email Here"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-up mil-mb-30">
                      <label className="mil-upper">
                        <span>Phone</span>
                        <span className="mil-required">*</span>
                      </label>
                      <input
                        type="tel"
                        name="tel"
                        placeholder="Enter Your Phone Here"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-up mil-mb-30">
                      <label className="mil-upper">
                        <span>Budget</span>
                        <span className="mil-required">*</span>
                      </label>
                      <input
                        type="text"
                        name="budget"
                        placeholder="Enter Your Name Here"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mil-input-frame mil-dark-input mil-up mil-mb-30">
                      <label className="mil-upper">
                        <span>Message</span>
                        <span className="mil-required">*</span>
                      </label>
                      <textarea
                        name="message"
                        placeholder="Enter Your Name Here"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mil-checbox-frame mil-dark-input mil-up mil-mb-30">
                      <input
                        checked
                        className="mil-checkbox"
                        id="checkbox-1"
                        type="checkbox"
                        value="value"
                      />
                      <label className="mil-text-sm">
                        Accept the terms and conditions of personal data.
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button className="mil-button mil-up" disabled>
                      Send Now
                    </button>
                  </div>
                </div>
                <div
                  className="alert-success mil-mb-90"
                  style={{ display: "none" }}
                >
                  <h5>Thanks, your message is sent successfully.</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
